import { useEffect, useState } from 'react';
import { useGeolocation } from './useGeolocation';
import { findNearestLocale, getLocaleAndAreaId } from 'utils/misc';

export const useNearbyLocale = (init = {}) => {
  const [locale, setLocale] = useState(init.locale);
  const [areaId, setAreaId] = useState(init.areaId || 0);
  const [coords, isGeolocated] = useGeolocation();
  const [isNearest, setIsNearest] = useState(false);

  const changeLocale = (value) => {
    const [id, area] = getLocaleAndAreaId(value);

    setLocale(id);
    setAreaId(area);
    setIsNearest(false);
  };

  useEffect(() => {
    if (isGeolocated) {
      const [id, area] = findNearestLocale(coords);
      setLocale(id);
      setAreaId(area);
      setIsNearest(true);
    }
  }, [isGeolocated, coords]);

  return {
    locale: locale.toUpperCase(),
    areaId,
    changeLocale,
    isNearest,
  };
};
