import dynamic from 'next/dynamic';
import SEO from '../components/common/Seo';
import CallToAction from '../components/common/CallToAction';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/home/Hero';
import RoomsPreview from '../components/home/RoomsPreview';
import TypeOfRooms from '../components/home/TypeOfRooms';
import Testimonial from '../components/home/Testimonial';
import { fetchTestimonials } from '../utils/fetchTestimonials';

export const getStaticProps = async () => {
  const testimonials = await fetchTestimonials();

  return { props: { testimonials } };
};

const Home = ({ testimonials }) => {
  return (
    <>
      <SEO pageDescription='Privacy guaranteed, only at Victoria Court. More than the one-of-a-kind rooms with meticulously designed unique thematic rooms, every stay at Victoria Court is unique on its own!' />

      <Header
        additionalItems={['book_now']}
        className={(isScrolledDown) =>
          isScrolledDown ? 'bg-white is-sticky' : ''
        }
        menuTextStyle={(isScrolledDown) =>
          isScrolledDown ? 'text-black' : 'text-white'
        }
        menuBgStyle={() => 'bg-lollipop -purple'}
        logo={(isScrolledDown) => (isScrolledDown ? 'black' : 'white')}
      />

      <Hero />

      <section className='layout-pt-sm layout-pb-md'>
        <div className='container'>
          <div className='sm:pt-20'>
            <RoomsPreview />
          </div>
        </div>
      </section>

      <section className='layout-pt-md layout-pb-md'>
        <div className='container'>
          <div className='col-auto mb-40'>
            <div className='sectionTitle -md'>
              <h2 className='sectionTitle__title text-center'>Type of Rooms</h2>
            </div>
          </div>

          <TypeOfRooms />
        </div>
      </section>

      <section className='layout-pt-lg layout-pb-lg bg-dark-1'>
        <div className='container'>
          <div className='row y-gap-40 justify-between'>
            <div className='col-xl-5 col-lg-6' data-aos='fade-up'>
              <h2 className='mt-30 text-30 text-white'>
                What our customers are
                <br /> saying about us?
              </h2>
              <p className='mt-20 text-white'>
                Here in Victoria Court, it is our mission to provide distinct,
                private, romantic, and playful stage for couples, families, and
                friends
              </p>
            </div>

            <div className='col-lg-6'>
              <div
                className='overflow-hidden js-testimonials-slider-3'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <Testimonial data={testimonials} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <CallToAction />

      <Footer />
    </>
  );
};

export default dynamic(() => Promise.resolve(Home), { ssr: false });
