import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import format from 'date-fns/format';
import DatePicker from '../common/DatePicker';
import LocaleList from '../common/LocaleList';
import { formatToDatetime, getMinReserveDatetime } from 'utils/dateUtils';
import { LOCALES } from 'utils/constants';

// TODO: Consider adding length of stay
const MainFilterSearchBox = () => {
  const router = useRouter();

  const minReservationDate = getMinReserveDatetime();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { date: formatToDatetime(minReservationDate) },
  });

  const [selectedLocale, setSelectedLocale] = useState();
  const [areaId, setAreaId] = useState(1);

  // ! We need to do this since we don't get any value right away
  useEffect(() => {
    if (router.isReady) {
      setSelectedLocale(router.query.locale);
    }

    return () => {};
  }, [router]);

  const onSubmit = (values) => {
    router.push(
      {
        pathname: '/rooms',
        query: {
          locale: selectedLocale?.toLowerCase(),
          areaid: areaId,
          reservedate: values.date,
        },
      },
      '/rooms'
    );
  };

  return (
    <form className='mt-30 md:mt-20' onSubmit={handleSubmit(onSubmit)}>
      <div className='mainSearch -w-900 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100'>
        <div className='button-grid items-center'>
          <div className='searchMenu-loc px-30 lg:py-20 lg:px-0 js-form-dd js-liverSearch'>
            <div
              data-bs-toggle='dropdown'
              data-bs-auto-close='true'
              data-bs-offset='0,22'
            >
              <h4 className='text-15 fw-500 ls-2 lh-16'>Choose a locale</h4>
              <div className='text-15 text-gray ls-2 lh-16'>
                {selectedLocale
                  ? LOCALES[selectedLocale.toUpperCase()]
                  : 'Where do you want to stay?'}
              </div>
            </div>

            <LocaleList
              showAll={false}
              locale={selectedLocale}
              areaId={areaId}
              updateLocation={(locale, area) => {
                setSelectedLocale(locale);
                setAreaId(area);
              }}
            />
          </div>

          <div className='searchMenu-date px-30 lg:py-20 lg:px-0'>
            <DatePicker
              errors={errors}
              state={register('date', {
                required: 'This field is required',
                min: {
                  value: formatToDatetime(minReservationDate),
                  message: `Date cannot be before ${format(
                    minReservationDate,
                    'MMMM dd, yyyy hh:mm a'
                  )}`,
                },
              })}
            />
          </div>

          <div className='button-item'>
            <button
              type='submit'
              className='mainSearch__submit button -dark-1 h-60 col-12 rounded-100 bg-blue-1 text-white'
            >
              <i className='icon-search text-20 mr-10' />
              Check available rooms
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MainFilterSearchBox;
