import Link from 'next/link';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Parallax } from 'react-parallax';
import MainFilterSearchBox from './MainFilterSearchBox';
import useMediaQuery from '../../utils/hooks/useMediaQuery';

const data = [
  {
    img: 'img/vcfacade.webp',
    title: 'VICTORIA COURT',
    description: '',
    link: {
      pathname: '/rooms',
      query: {
        isparty: false,
      },
    },
    linkText: 'Book now',
    linkProps: {
      as: '/rooms',
    },
  },
  {
    img: 'img/vc_wasted.webp',
    imgMobile: 'img/vc_mobile_wasted.webp',
    title: 'PARTY ROOMS',
    description: `It's a NEVER-BORING BASH at Victoria Court! Life's too short for boring parties! Experience never-ending fun at Victoria Court! Ready to make it happen?`,
    link: {
      pathname: '/rooms',
      query: {
        isparty: true,
      },
    },
    linkText: 'Book now',
    linkProps: {
      as: '/rooms',
    },
  },
  {
    img: 'img/vc_bites.webp',
    imgMobile: 'img/vc_mobile_bites.webp',
    title: 'VICTORIA BITES',
    description:
      'Victoria Bites brings the HOME-COOKED GOODNESS, straight to your doorstep. Order now and satisfy your cravings anytime, anywhere. AVAILABLE 24/7 for pick-up or delivery!',
    link: 'https://www.victoriabites.com/',
    linkProps: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    img: 'img/vc_style.webp',
    imgMobile: 'img/vc_mobile_style.webp',
    title: 'VICTORIA STYLE',
    description:
      'Hip, cool, and relevant pieces that scream "Victoria Court OG"! Show off your Victoria Court statements in style. Shop now!',
    link: 'https://webstore.paynamics.net/victoriacourtvc',
    linkProps: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
];

const Hero = () => {
  const isMobile = useMediaQuery('(max-width: 992px)');
  return (
    <section className='masthead -type-7 z-5'>
      <div className='masthead__bg masthead-slider js-masthead-slider-7'>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          direction='horizontal'
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          navigation={{
            nextEl: '.hero7-next-active',
            prevEl: '.hero7-prev-active',
          }}
        >
          {data.map((item, i) => (
            <SwiperSlide key={i}>
              <div className='row justify-center text-center'>
                <div className='col-auto'>
                  <div className='masthead__content'>
                    <div className='masthead__bg'>
                      <Parallax
                        strength={400}
                        bgImage={
                          isMobile ? item.imgMobile || item.img : item.img
                        }
                        bgImageAlt={
                          isMobile ? item.imgMobile || item.img : item.img
                        }
                        bgClassName='object-fit-cover'
                        className='h-100'
                      />
                    </div>
                    <div className='text-center'>
                      <h1
                        className='text-60 lg:text-40 md:text-30 text-white'
                        data-aos='fade-up'
                        data-aos-delay='300'
                      >
                        {item.title}
                      </h1>
                      <div
                        className='text-white hero-description'
                        data-aos='fade-up'
                        data-aos-delay='100'
                      >
                        <p className='text-white'>{item.description}</p>
                      </div>

                      {item.link && (
                        <div className='ctaCard__btn d-inline-block layout-pt-md'>
                          <Link
                            href={item.link}
                            className='button px-48 py-15 -lollipop -min-180 bg-lollipop text-white'
                            data-aos='fade-up'
                            data-aos-delay='300'
                            {...(item.linkProps ?? {})}
                          >
                            {item.linkText ?? 'Visit Site'}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className='masthead-slider__nav -prev js-prev mt'>
          <button className='button -outline-white size-50 flex-center text-white rounded-full hero7-prev-active'>
            <i className='icon-arrow-left' />
          </button>
        </div>
        <div className='masthead-slider__nav -next js-next'>
          <button className='button -outline-white size-50 flex-center text-white rounded-full hero7-next-active'>
            <i className='icon-arrow-right' />
          </button>
        </div>
      </div>
      <div className='relative z-5'>
        <MainFilterSearchBox />
      </div>
    </section>
  );
};

export default Hero;
