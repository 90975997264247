import Link from 'next/link';

const CallToAction = () => {
  return (
    <section className='layout-pt-md layout-pb-md bg-dark-2'>
      <div className='container'>
        <div className='row y-gap-30 justify-between items-center'>
          <div className='col-auto'>
            <div className='row y-gap-20  flex-wrap items-center'>
              <div className='col-auto'>
                <div className='icon-newsletter text-60 sm:text-40 text-white' />
              </div>
              <div className='col-auto'>
                <h4 className='text-26 text-white fw-600'>
                  Your Journey at Victoria Court Starts Here
                </h4>
                <div className='text-white'>
                  Book a room now and enjoy the best hotel experience you can
                  get!
                </div>
              </div>
            </div>
          </div>

          <div className='col-auto'>
            <Link
              href='/rooms'
              as='/rooms'
              className='button -md h-60 bg-lollipop -purple text-20 text-white'
            >
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
