export const fetchTestimonials = () =>
  fetch(`${process.env.NEXT_PUBLIC_ASSETS_URL}/webcontent.json`)
    .then((res) => res.json())
    .then((data) => data['web|home|testimonial'] ?? []);

// Few assumptions that can break this
// - the content is in .description and can contain two parts: header (h1) and body (p)
// - the header can be empty
// - if body is empty, we just return null so it can be filtered out
// so if the html tag used is changed, this might break or just return empty
export const cleanTestimonialData = (item) => {
  const idx = item.name.trim().indexOf(' ');
  let name;
  let description = '';

  // we only have a name
  if (idx < 0) {
    name = item.name.trim();
  } else {
    name = item.name.substring(0, idx);
    description = item.name.substring(idx + 1);
  }

  const temp = document.createElement('div');
  temp.innerHTML = item.description;
  const header = temp.querySelector('h1')?.textContent?.trim?.() || '';
  const body = temp.querySelector('p')?.textContent?.trim?.() || '';

  if (!body) return null;

  return {
    id: name,
    src: '/img/VClogo.png',
    name: name || 'Anonymous',
    description,
    header,
    body,
  };
};
