export const rooms = {
  standard: [
    'hi/000015.jpg',
    'hi/000014.jpg',
    'pa/000006.jpg',
    'pa/000007.jpg',
  ],
  deluxe: [
    'hi/000005.jpg',
    'hi/000006.jpg',
    'hi/000007.jpg',
    'pa/000004.jpg',
    'ne/000010.jpg',
    'ne/000012.jpg',
    'ne/000015.jpg',
    'ne/000005.jpg',
    'ne/000006.jpg',
    'sf/000002.jpg',
    'sf/000003.jpg',
    'sf/000005.jpg',
    'sf/000001.jpg',
    'lp/000003.jpg',
    'lp/000001.jpg',
    'lp/000005.jpg',
    'lp/000006.jpg',
    'lp/000007.jpg',
    'lp/000008.jpg',
  ],
  suite: [
    'pa/000011.jpg',
    'pa/000012.jpg',
    'pa/000013.jpg',
    'pa/000014.jpg',
    'pa/000015.jpg',
    'pa/000018.jpg',
    'pa/000024.jpeg',
    'pa/000021.jpg',
    'sf/000004.jpg',
    'lp/000009.jpg',
  ],
  thematic: [
    'pa/000010.jpg',
    'pa/000001.jpg',
    'pa/000002.jpg',
    'pa/000019.jpg',
    'pa/000020.jpg',
    'ne/000016.jpg',
    'ne/000001.jpg',
  ],
};
