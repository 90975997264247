import Link from 'next/link';
import Image from 'next/image';
import { rooms } from '../../data/allRooms';

const TypeOfRooms = () => {
  return (
    <div className='row justify-between' data-aos='fade-up'>
      {data.map((item, i) => (
        <div className='col-lg-3 col-sm-6 aos-init aos-animate' key={i}>
          <Link
            className='blogCard -type-1 d-block '
            href={{
              pathname: '/rooms',
              query: {
                roomtype: item.title.toLowerCase(),
              },
            }}
            as='/rooms'
          >
            <div className='blogCard__image'>
              <div className='ratio ratio-4:3 rounded-4 rounded-8'>
                <Image
                  className='img-ratio js-lazy'
                  src={getImage(item.title)}
                  width={400}
                  height={300}
                  alt={`picture of a ${item.title} room`}
                />
              </div>
            </div>
            <div className='mt-20'>
              <h4 className='text-dark-1 text-18 fw-500'>{item.title}</h4>
              <p className='text-light-1 text-15 lh-14 mt-5 mb-20'>
                {item.description}
              </p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

const rand = (n) => Math.floor(Math.random() * (n - 1));

const getImage = (type) => {
  const data = rooms[type.toLowerCase()];
  const image = data[rand(data.length)];

  return `${process.env.NEXT_PUBLIC_UPLOADS_URL}/${image}`;
};

const data = [
  {
    title: 'Standard',
    description:
      'There’s nothing standard about our standard rooms! Each Victoria Court Standard Room is uniquely designed to ignite your imagination for a wonderful and pleasurable experience!',
  },
  {
    title: 'Deluxe',
    description:
      'More room for your imagination with the Victoria Court Deluxe Room! A bigger room with more interesting interiors, plus a mini refrigerator, all for your enjoyment!',
  },
  {
    title: 'Suite',
    description:
      'Playful yet sophisticated. Our suite rooms are designed to fulfill your fantasies with their well-thought-out interiors and themes that we can relate to. All of our suite rooms come with either a bathtub, a sauna, or a jacuzzi',
  },
  {
    title: 'Thematic',
    description:
      'Enter a different world with our thematic rooms. Once you step inside, you’ll be transformed into a world of surprises brought to you by the thematic interiors of the room. These rooms also come with either a bathtub, a sauna, or a jacuzzi, plus a video karaoke machine.',
  },
];

export default TypeOfRooms;
