import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import RoomCard from './RoomCard';
import RoomsSkeleton from './RoomsSkeleton';

const RoomsSwiper = ({ meta, rooms, arrowClassName, dotsClassName }) => {
  return (
    <>
      {rooms ? (
        <>
          <Swiper
            spaceBetween={30}
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: '.js-hotels-next',
              prevEl: '.js-hotels-prev',
            }}
            pagination={{
              el: '.js-hotels-pag',
              clickable: true,
            }}
            breakpoints={{
              540: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 22,
              },
              1024: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            {rooms
              .filter((room) =>
                Object.keys(room.rates).includes(meta.rate.toString())
              )
              .map((item, i) => (
                <SwiperSlide
                  key={`${item.locale || meta.locale}-${meta.area}-${item.id}`}
                >
                  <div data-aos='fade' data-aos-delay={20 * (i + 1)}>
                    <RoomCard
                      locale={item.locale || meta.locale}
                      rate={meta.rate}
                      date={meta.date}
                      data={item}
                      otherData={{ isBookingForParty: meta.isParty }}
                      index={i}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>

          <div className='d-flex x-gap-15 items-center justify-center sm:justify-start pt-40 sm:pt-20'>
            <div className='col-auto'>
              <button className='d-flex items-center text-24 arrow-left-hover js-hotels-prev'>
                <i className={`icon icon-arrow-left ${arrowClassName}`} />
              </button>
            </div>

            <div className='col-auto'>
              <div
                className={`pagination -dots text-border js-hotels-pag ${dotsClassName}`}
              />
            </div>

            <div className='col-auto'>
              <button className='d-flex items-center text-24 arrow-right-hover js-hotels-next'>
                <i className={`icon icon-arrow-right ${arrowClassName}`} />
              </button>
            </div>
          </div>
        </>
      ) : (
        <RoomsSkeleton n={4} />
      )}
    </>
  );
};

export default RoomsSwiper;
