import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useGeolocation = (opts) => {
  const router = useRouter();
  const [coords, setCoords] = useState();
  const [isGeolocated, setIsGeolocated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (router.isReady && navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setCoords(pos.coords);
          setIsGeolocated(true);
          setIsLoading(false);
        },
        () => {
          setIsGeolocated(false);
          setIsLoading(false);
        },
        {
          enableHighAccuracy: false,
          maximumAge: 3 * 60 * 1000,
          timeout: 5000,
          ...(opts ?? {}),
        }
      );
    }
  }, [opts, router.isReady]);

  return [coords, isGeolocated, isLoading];
};
