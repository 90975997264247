import { formatToDatetime, getMinReserveDatetime } from 'utils/dateUtils';

// ! Can't clear the date as it will throw an error
const DatePicker = ({ errors, state }) => {
  return (
    <>
      <h4 className='text-15 fw-500 ls-2 lh-16'>Check in</h4>

      <div className='text-15 text-light-1 ls-2 lh-16'>
        <input
          className='custom_input-picker'
          type='datetime-local'
          name='checkin'
          id='checkin'
          {...state}
        />
        {errors?.date && (
          <p className='text-12 text-red-1'>{errors?.date?.message}</p>
        )}
      </div>
    </>
  );
};

export default DatePicker;
