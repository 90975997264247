import Image from 'next/image';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Autoplay } from 'swiper';
import { cleanTestimonialData } from '../../utils/fetchTestimonials';

const Testimonial = ({ data }) => {
  return (
    <>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        scrollbar={{
          el: '.js-scrollbar',
          draggable: true,
        }}
        modules={[Scrollbar, Autoplay]}
      >
        {data
          .map(cleanTestimonialData)
          .filter((item) => item)
          .map((item) => (
            <SwiperSlide key={item.id}>
              <div className='row items-center x-gap-15 y-gap-20'>
                <div className='col-auto'>
                  <Image
                    width={50}
                    height={50}
                    src={item.src}
                    alt='image'
                    className='js-lazy rounded-circle'
                  />
                </div>
                <div className='col-auto'>
                  <h5 className='text-18 text-white fw-500'>{item.name}</h5>
                  <div className='text-15 text-light-2 lh-15'>
                    {item.description}
                  </div>
                </div>
              </div>
              <div className='fw-500 mt-30 sm:mt-20'>
                <h6 className='text-22 text-light-1'>{item.header}</h6>
                <p className='text-18 text-light-2 '>{item.body}</p>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      <div className='d-flex items-center mt-60 sm:mt-20 js-testimonials-slider-pag'>
        <div className='text-light-3 fw-500 js-current'>01</div>
        <div className='slider-scrollbar bg-border bg-blue-2 ml-20 mr-20 w-max-300 js-scrollbar' />
        <div className='text-light-3 fw-500 js-all'>
          {new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 }).format(
            data.length
          )}
        </div>
      </div>
    </>
  );
};

export default Testimonial;
