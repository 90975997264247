import { useEffect, useState } from 'react';
import useSWR from 'swr';
import RoomsSwiper from 'components/common/RoomsSwiper';
import { useNearbyLocale } from 'utils/hooks/useNearbyLocale';
import { getMinReserveDatetime } from 'utils/dateUtils';
import { LOCALES_BY_AREA, LOCALES_WITH_AREA_ID } from 'utils/constants';
import { getBranchName, serialize } from 'utils/misc';

const DEFAULT_RATE = 5;

const fetcher = (...args) =>
  fetch(...args)
    .then((res) => res.json())
    .then((data) => data.rooms);

const RoomsPreview = () => {
  const { data } = useSWR(
    `/api/rooms?${serialize({
      rate: DEFAULT_RATE,
      date: getMinReserveDatetime(),
      all: true,
    })}`,
    fetcher
  );
  const [filteredData, setFilteredData] = useState(null);

  const { locale, areaId, changeLocale, isNearest } = useNearbyLocale({
    locale: 'ALL',
    areaId: 0,
  });

  useEffect(() => {
    if (!data) {
      setFilteredData(null);

      // always ALL in init anyways so also serves as filtered init
    } else if (locale === 'ALL') {
      setFilteredData(data);
    } else {
      const final = data
        .filter((item) => item.locale.toLowerCase() === locale.toLowerCase())
        .filter((item) => +item.area_id === +areaId);

      setFilteredData(final);
    }
  }, [data, locale, areaId]);

  return (
    <>
      <div className='row y-gap-10 justify-between items-end'>
        <div className='col-auto'>
          <div className='sectionTitle -md'>
            <h2 className='sectionTitle__title'>
              Rooms {isNearest ? 'Near You' : ''}
            </h2>
            <p className='sectionTitle__text mt-5 sm:mt-0'>
              {locale === 'ALL'
                ? 'These are all the available rooms in all locales'
                : `These are the available rooms for
          ${getBranchName(locale, areaId)}`}
            </p>
          </div>
        </div>
        <div className='col-sm-auto'>
          <select
            className='form-select rounded-4 border-light justify-between text-16 fw-500 px-20 h-50 w-180 sm:w-full text-14'
            value={locale + areaId}
            onChange={(e) => changeLocale(e.target.value)}
          >
            <option value='ALL0'>All Locales</option>
            {Object.entries(LOCALES_BY_AREA).map(([location, locales]) => (
              <optgroup key={location} label={location}>
                {locales.map((id) => (
                  <option key={id} value={id}>
                    {LOCALES_WITH_AREA_ID[id]}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
      </div>

      <div className='overflow-hidden pt-20 sm:pt-15'>
        <RoomsSwiper
          meta={{
            locale,
            area: areaId,
            rate: DEFAULT_RATE,
            date: getMinReserveDatetime(),
          }}
          rooms={filteredData}
          dotsClassName='text-yellow-1'
          arrowClassName='text-yellow-1'
        />
      </div>
    </>
  );
};

export default RoomsPreview;
